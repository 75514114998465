import { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { TimeContext, EventContext, AnalyticsContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";
import appLogo from "../img/logoGroup.png";
import arrowImg from "../img/arrow.png";
import { BrandAd } from "Components/Buttons/BrandAd";

export const Footer = ({
  showFooter,
  registration,
  registered,
  className,
  voting,
  postCampaign,
}) => {
  const { time } = useContext(TimeContext);
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event?.meta?.storageDirectory;
 
  const {
    // brand: brandLogo,
    // event: eventLogo,
    secondary: secondaryLogo
  } = event.logos;
 
  let secondaryLogoImg = `${api}/o/${storageDirectory}%2F${secondaryLogo?.image}?alt=media`;

  const footerMenu = false;
  const showLogo = true;

  const {
    heading,
    subHeading,
    headingRegistered,
    subHeadingRegistered,
    headingPostCampaign,
    maxPolicy,
    brandDisclaimer,
    sweepsDisclaimer,
    grandPrizeDisclaimer,
  } = event.footer;

  const logoClickHandler = (link) => {
    logClick({
      label: "Footer Logo",
      url: link,
    });
  };

  const footerMenuClickHandler = (link) => {
    logClick({
      label: "Footer Menu Link",
      url: link,
    });
  };

  

  return (
    <>
      {showFooter && (
        <FooterWrapper as="footer" className={className}>
          <FooterContainer>
            <ArrowWrapper>
              <Link
                 href={secondaryLogo.link}
                target="_blank"
                rel="noopener noreferrer"
                 onClick={() => logoClickHandler(secondaryLogo.link)}
              >
                {secondaryLogoImg && <Logo src={secondaryLogoImg} />}
              </Link>
            </ArrowWrapper>

            {postCampaign ? (
              <PostHeading>{ReactHtmlParser(headingPostCampaign)}</PostHeading>
            ) : registered ? (
              <>
                {headingRegistered && (
                  <Heading>{ReactHtmlParser(headingRegistered)}</Heading>
                )}
                {subHeadingRegistered && (
                  <SubHeading>
                    {ReactHtmlParser(subHeadingRegistered)}
                  </SubHeading>
                )}
              </>
            ) : (
              <>
                {heading && <Heading>{ReactHtmlParser(heading)}</Heading>}
                {subHeading && (
                  <SubHeading>{ReactHtmlParser(subHeading)}</SubHeading>
                )}
              </>
            )}

            {/* <BrandAd /> */}

            {brandDisclaimer && (
              <BrandDisclaimer>
                {ReactHtmlParser(brandDisclaimer)}
              </BrandDisclaimer>
            )}

            {(registration || postCampaign) && (
              <SweepsDisclaimer>
                {/* {!registered && ( */}
                  <SweepsDisclaimer>
                    {
                     event.endSweeps > time &&
                      sweepsDisclaimer &&
                      ReactHtmlParser(sweepsDisclaimer)}
                    {grandPrizeDisclaimer &&
                      ReactHtmlParser(grandPrizeDisclaimer)}
                  </SweepsDisclaimer>
                {/* )} */}
              </SweepsDisclaimer>
            )}

            {/* {footerMenu && (
                <FooterMenu>
                  {footerMenu.map((item, idx) => (
                    <li key={idx}>
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => footerMenuClickHandler(item.link)}
                      >
                        {item.text}
                      </a>
                    </li>
                  ))}
                </FooterMenu>
              )} */}

            <MaxPolicy>{ReactHtmlParser(maxPolicy)}</MaxPolicy>

            {/* {showLogo && (
              <Link
                href={secondaryLogo?.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => logoClickHandler(secondaryLogo?.link)}
              >
                <Logo src={secondaryLogoImg} />
              </Link>
            )} */}
          </FooterContainer>
        </FooterWrapper>
      )}
    </>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  font-size: 10px;
  padding: 0 15px;
  z-index: 1;
  background-color: #db0008;

  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 0 50px;
  }
`;

const FooterContainer = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 15px 0;
  position: relative;
  color: white;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 25px 0;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const Heading = styled.h1`
  text-align: center;
  font-size: 2rem;
  padding: 0 7em;

  @media (min-width: 951px) and (max-width: 1170px) {
    padding: 0 6em 0 6em;
  }

  @media (max-width: 950px) {
    font-size: 1.4rem;
    padding: 0 1em 0 5em;
    margin-right: -2em;
  }
`;

const PostHeading = styled.h1`
  text-align: center;
  font-size: 1.8rem;
  padding: 0 10em;
  margin-bottom: 30px;

  @media (min-width: 1460px){
    margin-bottom: 50px;  }

  @media (min-width: 951px) and (max-width: 1170px) {
    padding: 0 8em;
  }

  @media (max-width: 950px) {
    font-size: 1.75rem;
    padding: 0 2em 0 5em;
  }

  @media (max-width: 660px) {
    font-size: 1.45rem;
    padding: 0 2em 0 5em;
    margin-bottom: -10px;
  }
`;

const SubHeading = styled.p`
  text-align: center;
  margin-top: 0px;
  font-size: 1.4rem;
  padding: 0px 12em;

  @media (min-width: 951px) and (max-width: 1170px) {
    padding: 0 8.5em;
  }

  @media (max-width: 950px) {
    font-size: 0.85rem;
    padding: 0 0em 0 8em;
    line-height: 120%;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const ArrowWrapper = styled.div`
  position: absolute;
  left: -5%;
  top: 10%;

  @media (min-width: 951px) and (max-width: 1170px) {
    width: 25%;
    top: 5%;
    left: -10%;
  }

  @media (max-width: 950px) {
    width: 24%;
    top: 6%;
    left: -6%;
  }

  @media (max-width: 550px) {
    width: 40%;
    top: 7%;
    left: -10%;
  }
`;
const Link = styled.a`
 
`;

const Logo = styled.img`
  width: 100%;
  max-width: 300px;
`;

const SweepsDisclaimer = styled.div`
  margin-top: 1.5em;
  text-align: center;
  font-size: 0.5rem;

  @media (max-width: 950px) {
    font-size: 0.5rem;
    line-height: 130%;
  }
`;

const MaxPolicy = styled.p`
  text-align: center;
  font-size: 0.5rem;
  color: white;
  margin-top: 0px;

  a {
    color: white;
  }

  @media (max-width: 950px) {
    font-size: 0.6rem;
  }
`;

const BrandDisclaimer = styled.div`
  text-align: center;
  margin-top: 6em;
  font-size: 0.6rem;

  @media (max-width: 950px) {
    font-size: 0.6rem;
    margin-bottom: -10px;
  }
`;

const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;

  li {
    padding: 3px 8px;
  }

  a,
  a:visited {
    color: #031689;
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
  }
`;
