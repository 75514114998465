import React, { useContext, useState } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext, TempContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import {
  Modal,
  Media16By9,
  MediaVideo,
  HeroVideo,
  ButtonPrimary,
} from "Components";
import { RegistrationForm } from "Components/Form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const RegistrationLayout = React.memo(
  ({
    sweeps,
    postSweeps,
    ineligible,
    formStep = 0,
    registered,
    postCampaign,
    setIneligible,
  }) => {
    const { event } = useContext(EventContext);
    const { logVideoAction, logClick } = useContext(AnalyticsContext);

    const [open, setOpen] = useState(false);
    const [video, setVideo] = useState(null);

    const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
    const storageDirectory = event?.meta?.storageDirectory;

    // logo
    const { brand: brandLogo, hero, link } = event?.logos;
    let brandLogoImg = `${api}/o/${storageDirectory}%2F${brandLogo?.image}?alt=media`;
    let heroImg = `${api}/o/${storageDirectory}%2F${hero?.image}?alt=media`;

    if (registered) formStep = event?.register.sweeps.length - 1;

    // registration content
    const {
      heading,
      subHeading,
      subHeadingPostReg,
      subBodyPostSweep,
      body,
      bodyPostSweep,
      bodyPostReg,
      formHeading,
      formLegal,
      submit,
      pageCheck,
    } = sweeps
      ? event?.register.sweeps[formStep]
      : event?.register.postSweeps[formStep];

    // ineligible content
    const {
      heading: ineligibleHeading,
      body: ineligibleBody,
    } = event?.register;

    // music video background
    const {
      background: musicVideoBg,
      label: musicVideoLabel,
      video: musicVideoLink,
    } = event?.callToActions.musicVideo;
    let videoBgDesktop = `${api}/o/${storageDirectory}%2F${musicVideoBg.desktop}?alt=media`;
    let videoBgMobile = `${api}/o/${storageDirectory}%2F${musicVideoBg.mobile}?alt=media`;

    // brand cta
    const {
      btnLink: brandCTALink,
      btnText: brandCTAText,
      heading: brandCTAHeading,
    } = event?.callToActions.brand;

    // brand cta background
    const {
      desktop: brandBgD,
      mobile: brandBgM,
    } = event?.callToActions.brand.background;
    let brandBgDesktop = `${api}/o/${storageDirectory}%2F${brandBgD}?alt=media`;
    let brandBgMobile = `${api}/o/${storageDirectory}%2F${brandBgM}?alt=media`;

    // brand product background
    const {
      desktop: brandProductDesktop,
      mobile: brandProductMobile,
    } = event?.callToActions.brand.product;
    let productDesktop = `${api}/o/${storageDirectory}%2F${brandProductDesktop}?alt=media`;
    let productMobile = `${api}/o/${storageDirectory}%2F${brandProductMobile}?alt=media`;

    // post-campaign content
    const {
      heading: postCampaignHeading,
      subHeading: postCampaignSubHeading,
      body: postCampaignBody,
    } = event?.postCampaign;
    console.log("postC,", event.postCampaign);
    // brand cta (post-campaign)
    const {
      btnLink: brandSecondaryCTALink,
      btnText: brandSecondaryCTAText,
      heading: brandSecondaryCTAHeading,
    } = event?.callToActions.brandSecondary;

    const logoClickHandler = (link) => {
      logClick({ label: "Header Logo", url: link });
    };

    const brandCtaClickHandler = () => {
      logClick({ label: "Brand CTA", url: brandCTALink });
    };

    const ctaClickHandler = () => {
      logClick({
        label: "Post-Campaign Brand CTA",
        url: brandSecondaryCTALink,
      });
    };

    const setModalVisible = (bool, link) => {
      setVideo(link);
      setOpen(bool);
      logVideoAction({ bool, videoLink: link, videoTitle: "Hero Video" });
    };

    return (
      <Layout
        registration={postCampaign ? false : true}
        flexGrow
        showFooter
        registered={registered}
        postCampaign={postCampaign}
      >
        <Container postCampaign={postCampaign}>
          <Content>
            <LogoBox>
              <StyledLink
                href={brandLogo.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => logoClickHandler(brandLogo.link)}
              >
                {brandLogoImg && <Logo src={brandLogoImg} />}
              </StyledLink>
            </LogoBox>

            {/* <HeroVideoMobile>
              <HeroVideo setModalVisible={setModalVisible} />
            </HeroVideoMobile> */}

            <ContainerPadding>
              {!postCampaign ? (
                <>
                  {!ineligible ? (
                    <>
                      {/* ===== CAMPAIGN INTRO ===== */}
                      {heading && (
                        <MainHeading>{ReactHtmlParser(heading)}</MainHeading>
                      )}
                      {pageCheck && (
                        <>
                          <BodyPostSweep>
                            {ReactHtmlParser(bodyPostSweep)}
                          </BodyPostSweep>
                          <SubBodyPostSweep>
                            {ReactHtmlParser(subBodyPostSweep)}
                          </SubBodyPostSweep>
                        </>
                      )}
                      {subHeading && (
                        <SubHeading>{ReactHtmlParser(subHeading)}</SubHeading>
                      )}
                      {body && <Body>{ReactHtmlParser(body)}</Body>}
                      {subHeadingPostReg && (
                        <Body>{ReactHtmlParser(subHeadingPostReg)}</Body>
                      )}
                      {bodyPostReg && (
                        <SubHeading>{ReactHtmlParser(bodyPostReg)}</SubHeading>
                      )}

                      {registered && (
                        <ButtonContainer>
                          <StyledLinkForward
                            rel="noopener noreferrer"
                            target="_blank"
                            to="reminder"
                          >
                            {" "}
                            <StyledIcon icon={["fa", "comment"]} /> Sign Up for
                            a Text Reminder
                          </StyledLinkForward>
                          <StyledLinkForward
                            as="a"
                            href={link}
                            onClick={ctaClickHandler}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            <StyledIcon icon={["fab", "facebook"]} /> Follow
                            Bryce on Facebook
                          </StyledLinkForward>
                        </ButtonContainer>
                      )}

                      {/* ===== REGISTRATION FORM ===== */}
                      {!registered && (
                        <RegistrationForm
                          formPart={formStep}
                          formHeading={formHeading}
                          formLegal={formLegal}
                          submitButton={submit}
                          setIneligible={setIneligible}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {/* ===== INELIGIBLE ===== */}
                      {ineligibleHeading && (
                        <Heading>{ReactHtmlParser(ineligibleHeading)}</Heading>
                      )}
                      {ineligibleBody && (
                        <Body>{ReactHtmlParser(ineligibleBody)}</Body>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {/* ===== POST-CAMPAIGN HEADINGS ===== */}
                  {postCampaignHeading && (
                    <HeadingPostCampaign>
                      {ReactHtmlParser(postCampaignHeading)}
                    </HeadingPostCampaign>
                  )}
                  {postCampaignBody && (
                    <BodyPostCampaign>
                      {ReactHtmlParser(postCampaignBody)}
                    </BodyPostCampaign>
                  )}
                  {postCampaignSubHeading && (
                    <SubHeadingPostCampaign>
                      {ReactHtmlParser(postCampaignSubHeading)}
                    </SubHeadingPostCampaign>
                  )}

                  {/* ===== POST-CAMPAIGN CTA ===== */}
                  {/* <BrandSecondaryCTA>
                    <h3>{ReactHtmlParser(brandSecondaryCTAHeading)}</h3>
                    <ButtonPrimary
                      as="a"
                      href={brandSecondaryCTALink}
                      onClick={ctaClickHandler}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {brandSecondaryCTAText}
                    </ButtonPrimary>
                  </BrandSecondaryCTA> */}
                </>
              )}

              {(formStep !== 0 || ineligible || postCampaign) && (
                <PoweredBy>
                  {ReactHtmlParser(event?.register.poweredBy)}
                </PoweredBy>
              )}
            </ContainerPadding>
          </Content>

          <ArtistContent>
            <BrandImage desktop={heroImg} mobile={heroImg} />
          </ArtistContent>
        </Container>

        <Modal open={open} setOpen={setModalVisible}>
          <Media16By9>
            <MediaVideo
              src={video}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </Media16By9>
        </Modal>
      </Layout>
    );
  }
);

const Container = styled(MaxWidthContainer)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 0 25px 0;
  gap: 50px;
  background-color: #f7f7f7;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: row;
    padding: 0px 0px 0px 50px;
  }
`;

const Content = styled.div`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 33%;
    max-width: 400px;
    padding: 50px 0px;
  }

  @media (min-width: 950px) {
    min-height: 90vh;
  }

  @media (max-width: 950px) {
    padding: 0 15px;
  }
`;

const ContainerPadding = styled.div`
  padding: 0 15px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 0;
  }
`;

const LogoBox = styled.div`
  padding-bottom: 25px;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 950px) {
    padding: 20px 15px;
  }
`;

const StyledLink = styled.a`
  width: 100%;
  max-width: 60px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    max-width: 92px;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const StyledLinkForward = styled(Link)`
  color: #000;
  width: 70%;
  background: #ffbc0d;
  padding: 10px 10px 8px 10px;
  text-decoration: none;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 0.9rem;
  font-weight: 500;

  @media (min-width: 950px) {
    min-width: 250px;
  }

  @media (max-width: 950px) {
    font-size: 0.6em;
    margin-right: 7px;
    padding: 10px 0px 8px 8px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media (max-width: 550px) {
    flex-direction: row;
    margin-bottom: -50px;
  }

  @media (min-width: 551px) and (max-width: 950px) {
    flex-direction: column;
    margin-bottom: -50px;
    width: 60%;
  }
`;

const Logo = styled.img`
  width: 100%;
  display: flex;
`;

const MainHeading = styled.h1`
  border-bottom: 0px solid #707070;
  padding-bottom: 0px;
  color: #db0008;
  margin-bottom: -10px;
  font-size: 2rem;

  & > span {
    display: block;
    font-size: 30px;
  }

  @media (max-width: 950px) {
    font-size: 1.85rem;
    line-height: 120%;
  }

`;

const Heading = styled.h2``;

const SubHeading = styled.h3`
  color: #292929;
  font-size: 1rem;
  margin-top: 15px;
  margin-bottom: 0px;
  padding-right: 40px;
`;

//   ${(props) =>
//     props.pageCheck &&`
//    color: #fff;
//   `}
//   font-size: 1.1rem;
// `;

const Body = styled.p`
  color: #292929;
  font-size: 0.9rem;
  line-height: 140%;
  padding-right: 80px;
  margin-bottom: 20px;

  @media (max-width: 950px) {
    padding: 0;
  }
`;
const BodyPostSweep = styled.p`
  color: #292929;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 500;
`;

const SubBodyPostSweep = styled.p`
  color: #ffbc0d;
  font-size: 1rem;
  font-weight: 500;
  padding-right: 40px;
  line-height: 140%;
`;
const HeadingPostCampaign = styled(Heading)`
  color: #db0007;
`;

const SubHeadingPostCampaign = styled.h3`
  color: #2d2d2d;
  font-weight: 500;
  font-size: 1.05rem;
  padding-right: 30px;

  @media (max-width: 950px) {
    line-height: 100%;
  }
`;

const BodyPostCampaign = styled(Body)`
  @media (max-width: 950px) {
    font-size: 0.85rem;
  }
`;

const topBorder = `
  border-top: 0px solid #707070;
  padding-top: 24px;
  margin-top: 24px;
`;

const PoweredBy = styled.div`
  ${topBorder}
`;

const BrandSecondaryCTA = styled.div`
  ${topBorder}
`;

const ArtistContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ArtistContentTop = styled.div`
  display: flex;
`;

const BrandImage = styled.div`
  flex: 1;
  background-image: url(${(props) => props.mobile});
  background-position: bottom;
  background-size: cover;
  padding-top: 56.25%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.desktop});
    background-position: center;
  }

  @media (max-width: 950px) {
    padding-top: 80%;
    margin-bottom: -30px;
    margin-top: -30px;
  }
`;

const HeroVideoMobile = styled.div`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const HeroVideoDesktop = styled.div`
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: flex;
    width: 66%;
  }
`;

const MusicVideo = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex: 1 1 0%;
    padding: 0;
  }
`;

const MobileImage = styled.img`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const DesktopImage = styled.img`
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: block;
  }
`;

const BrandCTA = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 16/9;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    position: absolute;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    & > img {
      width: 150%;
    }
  }
`;

const BrandCTAHeading = styled.h3`
  color: #ffffff;
  padding: 0 20px;
  margin: 0 0 40px;
  z-index: 1;
`;

const Label = styled.div`
  background-color: rgba(0, 9, 91, 0.9);
  color: #ffffff;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  text-decoration: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 12px;
  }
`;
